<template lang="pug">
.main-wrapper.caixas-listar
	header.main-wrapper-header
		.p-grid.p-align-center
			.p-col-12.ta-right
				h1.text-header.text-secondary MedClub / <b>Comunicação Interna</b>

	Dialog.dialogMensagem(header='Alertas Importantes:' :visible.sync='dialogAlertasImportantes' :modal='true' :closable='false')
		div(:multiple="true" :activeIndex="[0]" v-for='msg in this.msgs_alerta_importante' :key='msg.nm_titulo' )
				Panel(v-if="!msg.aq_imagem_principal" :header="msg.nm_titulo" :toggleable="true")
					div.p-grid.p-align-center
						div.p-lg-8.p-sm-12.p-md-12.ta-left
							p.modal-title Data: <b>{{ msg.dt_inicial }}</b>
							p.modal-title Título: <b>{{ msg.nm_titulo }}</b>
							p.modal-title Mensagem:
							p.modal-text(v-if='msg.nm_texto')
								span(v-html="msg.nm_texto")
							Message(v-if="msg.visualizado" severity="success" :sticky="true" :closable="false") Mensagem visualizada.
						div.p-lg-4.p-sm-12.p-md-12.ta-left
							ProgressBar(v-if="waitingAnexos" mode="indeterminate")
							div.p-grid(v-else)
								Panel.p-col-12(header="Arquivos Anexados")
									div.p-grid.p-fluid.p-align-top(v-if="anexos.length > 0" style="margin-top: 0.2vh")
										a.p-lg-6.p-sm-12.p-md-12.ta-center.anexo-importantes(v-for='anexo in anexos' v-if="anexo.msg_id == msg.id" :key='anexo.id' :href='anexo.url' target='_blank')
											a {{ anexo.filename }}
									p(v-else) Nenhum anexo.

				Panel(v-else :header="msg.nm_titulo" :toggleable="true")
						div.p-grid.p-fluid.p-align-top
							div.p-lg-8.p-sm-12.p-md-12.p-grid.ta-top
								div.p-col-12
									p.modal-title Data: <b>{{ msg.dt_inicial }}</b>
									p.modal-title Título: <b>{{ msg.nm_titulo }}</b>
									p.modal-title Mensagem:
									div.modal-text(v-if='msg.nm_texto')
										span(v-html="msg.nm_texto")
									Message(v-if="msg.visualizado" severity="success" :sticky="true" :closable="false") Mensagem visualizada.
							div.p-lg-4.p-sm-12.p-md-12.ta-left
								p.modal-title Imagem anexada:
								div.popup-image-wrapper
									img(:src='msg.aq_imagem_principal')
								ProgressBar(v-if="waitingAnexos" mode="indeterminate")
								div.p-grid(v-else)
									Panel.p-col-12(header="Arquivos Anexados")
										div.p-grid.p-fluid.p-align-top(v-if="anexos.length > 0" style="margin-top: 0.2vh")
											a.p-lg-6.p-sm-12.p-md-12.ta-center.anexo-importantes(v-for='anexo in anexos' v-if="anexo.msg_id == msg.id" :key='anexo.id' :href='anexo.url' target='_blank')
												a {{ anexo.filename }}
										p(v-else) Nenhum anexo.

								div.p-grid.p-fluid.p-align-top(v-if="msg.link")
									p.modal-title Links:
										div.p-col-12
											a(:href="msg.link" target="_blank") {{ msg.link }}
								p(v-else) Nenhum link.

		template(#footer)
			.p-grid.p-align-center.ta-center
				.p-col-12(v-if="!listaGrupos.includes(1)")
					Button.p-button-warning(label="Confirmo que li todos os Alertas Importantes. Fechar esta janela." icon="pi pi-exclamation-triangle" @click="dialogAlertasImportantes = false; visualizarImportantes()" autofocus )
				.p-col-12(v-else)
					Button.p-button-danger(label="Fechar" icon="pi pi-exclamation-triangle" @click="dialogAlertasImportantes = false;" autofocus )
	Dialog(header='Excluir Mensagem' :visible.sync='dialogApagar' :modal='true')
		.p-grid.ta-center(style="width: 20rem")
			.p-col-12
				b.modal-title(style="font-size: 1rem") Deseja excluir essa Mensagem?
			.p-col-12(v-if="waitingForm")
				ProgressBar(mode="indeterminate")
			div.p-col-12.p-grid(v-else)
				.p-col-6
					Button.p-button(label='Não' @click='dialogApagar = false' style="width: 100%")
				.p-col-6
					Button.p-button-danger(label='Excluir' @click='remove_msg(dialogApagar_data)' style="width: 100%")

	Dialog(header='Excluir Anexo' :visible.sync='dialogApagarAnexo' :modal='true')
		.p-grid.ta-center(style="width: 20rem")
			.p-col-12
				b.modal-title(style="font-size: 1rem") Deseja excluir esse Anexo?
			.p-col-12(v-if="waitingAnexos")
				ProgressBar(mode="indeterminate")
			div.p-col-12.p-grid(v-else)
				.p-col-6
					Button.p-button(label='Não' @click='dialogApagarAnexo = false' style="width: 100%")
				.p-col-6
					Button.p-button-danger(label='Excluir' @click="remove_anexo(dialogApagarAnexo_data);" style="width: 100%")

	Dialog.dialogCadastroMensagem(:header="`${ model_msg.id ? 'Editar' : 'Adicionar' } Mensagem`" :visible.sync='dialogCadastroMensagem' :modal='true' @hide='limpar_model()')
		form(@submit.prevent='handleSubmit()')
			.p-grid.p-col-12(style="display:flex; justify-content:center; width: 85vw; overflow: scroll")
				.p-sm-12.p-md-8.p-lg-8.p-grid.p-fluid.p-align-top(:class="{'form-group--error': submitted && $v.model_msg.nm_titulo.$error }")
					div.p-sm-12.p-md-12.p-lg-12
						label.form-label Título (No máximo 64 caracteres):
						InputText(type='text' v-model='model_msg.nm_titulo' maxLength='64' required)
						.feedback--errors(v-if='submitted && $v.model_msg.nm_titulo.$error')
							.form-message--error(v-if="!$v.model_msg.nm_titulo.minLength") <b>O título</b> deve ter pelo menos 2 caracteres.
							.form-message--error(v-if="!$v.model_msg.nm_titulo.maxLength") <b>O título</b> deve ter no máximo 150 caracteres.
							.form-message--error(v-if="!$v.model_msg.nm_titulo.required") <b>O título</b> é obrigatório.
					div.p-sm-12.p-md-12.p-lg-12(:class="{'form-group--error': submitted && $v.model_msg.nm_titulo.$error }")
						label.form-label Corpo da Mensagem:
						Editor(
							v-model="model_msg.nm_texto",
							editorStyle="height: 320px"
						)
						.feedback--errors(v-if='submitted && $v.model_msg.nm_texto.$error')
							.form-message--error(v-if="!$v.model_msg.nm_texto.maxLength") <b>O corpo da Mensagem</b> deve ter pelo menos 2 caracteres.
							.form-message--error(v-if="!$v.model_msg.nm_texto.required") <b>O corpo da Mensagem</b> é obrigatório.
					div.p-sm-12.p-md-4.p-lg-4
						label.form-label Tipo de Mensagem:
						.p-inputgroup
							SelectButton(
								:options='options.tipo_msg'
								optionLabel='label'
								optionValue='value'
								v-model='model_msg.ie_tipo'
							)
					div.p-sm-12.p-md-4.p-lg-4
						label.form-label Faz popup?
						.p-inputgroup
							SelectButton(
								:options='options.tipo_popup'
								optionLabel='label'
								optionValue='value'
								v-model='model_msg.ie_popup'
							)
					div.p-sm-12.p-md-4.p-lg-4
						label.form-label Importante? (Popup)
						.p-inputgroup
							SelectButton(
								:options='options.tipo_popup'
								optionLabel='label'
								optionValue='value'
								v-model='model_msg.ie_importante'
							)

					div.p-sm-12.p-md-12.p-lg-12
						p.modal-title(style="color: black") Arquivos anexados:
						.p-col-12.p-grid.anexos-wrapper
							.p-sm-12.p-md-12.p-lg-12.ta-center(v-if="waitingAnexos")
								p.modal-title(style="color: black") Procurando anexos
								ProgressBar(mode="indeterminate")
							div.p-sm-12.p-md-4.p-lg-4(v-else-if="anexos.length > 0" v-for='anexo in anexos' :key='anexo.id')
								.anexo
									i.icon(class="jam jam-attachment")
									a(:href='anexo.url' target='_blank') {{ anexo.filename }}
									Button.p-button-raised.p-button-rounded.p-button-danger(
										icon="jam jam-minus-circle"
										@click="dialogApagarAnexo=true;dialogApagarAnexo_data=anexo;"
										type="button"
									)

							.p-sm-8(v-else)
								i Sem anexos salvos.

					div(v-if="waitingSalvarAnexos").p-sm-12.p-md-12.p-lg-12.ta-center
						p(style="font-size: 1.2rem") Salvando Anexos . . .
						ProgressBar(mode="indeterminate" style="margin-top: 15px")
					div.file-input-container.p-sm-12.p-md-12.p-lg-12(v-else)
						form(@submit.prevent="adicionarAnexo")
						label.file-input-label(style="margin-top: 15px;" for="input-anexos") Selecione um ou mais anexos:
							i(class="jam jam-upload")
							input.file-input(type="file" ref="fileInput" id="input-anexos" @change="adicionarAnexo" multiple)
							p(v-for="(file, index) in anexos_add" :key="index") {{ file.name }}
							Button(v-if="this.anexos_add.length > 0 && !this.waitingForm" label='Remover todos' icon='jam jam-trash' @click="adicionarAnexo" type="reset")

					div.p-grid.p-col-12.p-fluid.p-align-top( style="display:flex; justify-content:center")
						div.p-sm-12.p-md-6.p-lg-6
							ProgressBar(v-if="waitingForm || waitingSalvarAnexos" mode="indeterminate")
							Button(v-else label='Salvar Mensagem' icon='jam jam-check' type="submit")


				.p-sm-12.p-md-4.p-lg-4.p-grid.p-fluid.p-align-top
					div.p-sm-12.p-md-12.p-lg-12
						label.form-label Data inicial:
						.p-inputgroup
							Calendar(
								v-model='model_msg.dt_inicial'
								dateFormat="dd/mm/yy"
								:locale="ptbr"
								:manualInput='false'
								:touchUI='windowInnerWidth < 576'
								:showTime="true"
								:showSeconds="true")
							Button.p-button-danger(
								icon='jam jam-rubber' type='button'
								v-tooltip.top="'Limpar'"
								@click='model_msg.dt_inicial=null')
						br
						label.form-label Data final:
						.p-inputgroup
							Calendar(
								v-model='model_msg.dt_final'
								dateFormat="dd/mm/yy"
								:locale="ptbr"
								:manualInput='false'
								:touchUI='windowInnerWidth < 576'
								:showTime="true"
								:showSeconds="true")
							Button.p-button-danger(
								icon='jam jam-rubber' type='button'
								v-tooltip.top="'Limpar'"
								@click='model_msg.dt_final=null')
						br
						label.form-label Link:
						InputText(
							type='text' v-model='model_msg.link'
							placeholder="Para o Card abrir o link, marque 'Faz Popup' como 'não'."
							)
						br
						br
						.p-grid.p-sm-12.p-md-12.p-lg-12(style="border: 1px dashed #8888;")
							.p-col-12
								p.title.ta-center Usuários alvo:
								.ta-center
									small (Em cada campo, para enviar a todos, deixar vazio.)
							.p-col-12
								label.form-label Estabelecimentos:
								ProgressBar(v-if='waiting_estabelecimento' mode="indeterminate")
								MultiSelect(
									v-else
									v-model='model_msg.lista_estabs'
									:options='options.estabelecimentos_multi'
									:filter='true'
									optionLabel='text'
									optionValue='value'
									dataKey='value'
									:showClear='true'
								)
							.p-col-12
								label.form-label Grupos:
								ProgressBar(v-if='waiting_grupos' mode="indeterminate")
								MultiSelect(
									v-else
									v-model='model_msg.lista_grupos'
									:options='options.grupos_multi'
									:filter='true'
									optionLabel='text'
									optionValue='value'
									dataKey='value'
									:showClear='true'
								)
						br
						label.form-label Imagem principal:
						.image-wrapper-form.p-col-12.p-grid.ta-center
							div.p-col-12
								i(v-if="!model_msg.aq_imagem_principal" class="jam jam-picture" style="font-size: 4rem;display: block;color: #575757; position: relative;")
								img(:src='model_msg.aq_imagem_principal' style="max-height: 225px")

							div.p-col-6
								FileUpload(
									name='aq_foto'
									chooseLabel='Procurar'
									mode='basic'
									accept="image/*"
									:auto='true'
									@before-send='beforeSendImage'
								)
							div.p-col-6
								Button(v-if="model_msg.aq_imagem_principal"
									label='Remover'
									icon='jam jam-trash'
									type="button"
									@click='model_msg.aq_imagem_principal=null'
								)
						br
						.p-col-12.p-grid.ta-left
							label.form-label Histórico de edições:
							.historico-wrapper.p-col-12
								span(v-html="model_msg.historico_edicoes")


	Dialog.dialogMensagem(header="Detalhes da Mensagem" :visible.sync='dialogMensagem' :modal='true')
		.p-grid.p-col-12(style="display:flex; justify-content:center; width: 85vw; overflow: scroll")
			div(v-if="!dialogMensagem_data.aq_imagem_principal")
				div.p-grid.p-align-center
					div.p-lg-8.p-sm-12.p-md-12.ta-left
						p.modal-title Data: <b>{{ dialogMensagem_data.dt_inicial }}</b>
						p.modal-title Título: <b>{{ dialogMensagem_data.nm_titulo }}</b>
						p.modal-title Mensagem:
						div.modal-text
							span(v-html="dialogMensagem_data.nm_texto")
					div.p-lg-4.p-sm-12.p-md-12.ta-left
						ProgressBar(v-if="waitingAnexos" mode="indeterminate")
						div.p-grid(v-else)
							Panel.p-col-12(header="Arquivos Anexados")
								div.p-grid.p-fluid.p-align-top(v-if="anexos.length > 0" style="margin-top: 0.2vh")
									a.p-lg-6.p-sm-12.p-md-12.ta-center.anexo-importantes(v-for='anexo in anexos' v-if="anexo.msg_id == dialogMensagem_data.id" :key='anexo.id' :href='anexo.url' target='_blank')
										a {{ anexo.filename }}
								p(v-else) Nenhum anexo.
						div.p-grid.p-fluid.p-align-top(v-if="dialogMensagem_data.link")
							p.modal-title Links:
								div.p-col-12
									a(:href="dialogMensagem_data.link" target="_blank") {{ dialogMensagem_data.link }}
						p(v-else) Nenhum link.

			div(v-else)
				div.p-grid.p-fluid.p-align-top
					div.p-lg-8.p-sm-12.p-md-12.p-grid.ta-top
						div.p-col-12
							p.modal-title Data: <b>{{ dialogMensagem_data.dt_inicial }}</b>
							p.modal-title Título: <b>{{ dialogMensagem_data.nm_titulo }}</b>
							p.modal-title Mensagem:
							div.modal-text
								span(v-html="dialogMensagem_data.nm_texto")
					div.p-lg-4.p-sm-12.p-md-12.ta-left
						p.modal-title Imagem anexada:
						div.popup-image-wrapper
							img(:src='dialogMensagem_data.aq_imagem_principal')
						ProgressBar(v-if="waitingAnexos" mode="indeterminate")
						div.p-grid(v-else)
							Panel.p-col-12(header="Arquivos Anexados")
								div.p-grid.p-fluid.p-align-top(v-if="anexos.length > 0" style="margin-top: 0.2vh")
									a.p-lg-6.p-sm-12.p-md-12.ta-center.anexo-importantes(v-for='anexo in anexos' v-if="anexo.msg_id == dialogMensagem_data.id" :key='anexo.id' :href='anexo.url' target='_blank')
										a {{ anexo.filename }}
								p(v-else) Nenhum anexo.
						div.p-grid.p-fluid.p-align-top(v-if="dialogMensagem_data.link")
							p.modal-title Links:
								div.p-col-12
									a(:href="dialogMensagem_data.link" target="_blank") {{ dialogMensagem_data.link }}
						p(v-else) Nenhum link.

	ProgressBar(v-if="waitingMensagens" mode="indeterminate")
	div(v-else-if='msgs_fixas.length == 0 && msgs_alerta.length == 0 && msgs_alerta_importante.length == 0')
		p.ta-center.text-secondary(style='margin-top: 40px;') Nenhuma mensagem encontrada.
		Button.p-button-raised.p-button-rounded.p-button-success.mr-1.p-col-2(v-if="listaGrupos.includes(1)" label="Adicionar Mensagem" @click="dialogCadastroMensagem = true; getGrupos({'clean': 'true'}); limpar_model();")
	div(v-else)
		div.p-grid.p-align-top.p-col-12.ta-right
			div.p-grid.p-align-top.p-col-2.p-md-2.p-sm-12
				div.card
					div.header-alertas(v-if="msgs_alerta_importante.length > 0" @click='dialogAlertasImportantes=true; getAnexos();')
						.ta-center
							p.title Alertas Importantes
					div.header-alertas(v-else)
						.ta-center
							p.title Alertas Importantes
					div.info-alertas(v-if="msgs_alerta_importante.length > 0")
						div(v-for='msg in this.msgs_alerta_importante' :key='msg.nm_titulo')
							p.card-alerta(
								v-if="checkVigencia(msg)"
								@click="dialogMensagem = true; dialogMensagem_data = msg; getAnexos(params={'cd_mensagem': msg.id})"
							) {{ msg.nm_titulo }}
							p.card-alerta(
								v-else
								style="border: 1px solid orange"
								v-tooltip.top="'Não Vigente'"
								@click="dialogMensagem = true; dialogMensagem_data = msg; getAnexos(params={'cd_mensagem': msg.id})"
							) {{ msg.nm_titulo }}
							
					div.info-alertas(v-else)
						p(style="padding: 15px;") Sem alertas importantes.


			div.p-grid.p-align-right.p-col-10
				a.card-fixos.p-col-2(style="border: 1px solid lightblue" v-if='msg.link && !msg.ie_popup' v-for='msg in this.msgs_fixas' :key='msg.nm_titulo' :href='msg.link' target="_blank")
					.alerta-vigencia(v-if="!checkVigencia(msg)")
						Button.p-button-rounded.p-button-warning(icon="pi pi-calendar" v-tooltip.top="'Não vigente.'")
					a(v-if='msg.link' style='pointer-events: none')
						div.msg-image-wrapper(v-if='msg.aq_imagem_principal')
								img(:src='msg.aq_imagem_principal')
						div.info
							p.title(v-tooltip.right="'Redirecionar para Link'") {{ msg.nm_titulo }}
								i.info-link(class='jam jam-link' )
							template
								div.conteudo.nm_texto(v-if="msg.aq_imagem_principal || msg.nm_texto?.length > 150" :style="{ color: 'black' }")
									span(v-html="textoFormatado(msg)")
								div(v-else :style="{ color: 'black', padding: '15px' }")
									span(v-html="textoFormatado(msg)")

				a.card-fixos.p-col-2(style="border: 1px solid lightgreen" v-if='msg.link && msg.ie_popup' v-for='msg in this.msgs_fixas' :key='msg.nm_titulo'
					@click="dialogMensagem = true; dialogMensagem_data = msg; getAnexos()")
					a(v-if='msg.link' style='pointer-events: none')
						div.msg-image-wrapper(v-if='msg.aq_imagem_principal')
								img(:src='msg.aq_imagem_principal')
						div.info
							p.title {{ msg.nm_titulo }}
								i.info-link(class='jam jam-arrows-corners' v-tooltip.right="'Redirecionar para Link'")
							template
								div.conteudo.nm_texto(v-if="msg.aq_imagem_principal || msg.nm_texto?.length > 150" :style="{ color: 'black' }")
									span(v-html="textoFormatado(msg)")
								div(v-else :style="{ color: 'black', padding: '15px' }")
									span(v-html="textoFormatado(msg)")

				a.card-fixos.p-col-2(style="border: 1px solid lightgreen" v-if='!msg.link && msg.ie_popup' v-for='msg in this.msgs_fixas' :key='msg.nm_titulo'
					@click="dialogMensagem = true; dialogMensagem_data = msg; getAnexos()")
					a(v-if='!msg.link' style='pointer-events: none')
						div.msg-image-wrapper(v-if='msg.aq_imagem_principal')
								img(:src='msg.aq_imagem_principal')
						div.info
							p.title {{ msg.nm_titulo }}
								i.info-link(class='jam jam-arrows-corners' v-tooltip.right="'Redirecionar para Link'")
							template
								div.conteudo.nm_texto(v-if="msg.aq_imagem_principal || msg.nm_texto?.length > 150" :style="{ color: 'black' }")
									span(v-html="textoFormatado(msg)")
								div(v-else :style="{ color: 'black', padding: '15px' }")
									span(v-html="textoFormatado(msg)")

				a.card-fixos.p-col-2(style="cursor: default;" v-if='!msg.link && !msg.ie_popup' v-for='msg in this.msgs_fixas' :key='msg.nm_titulo')
					a(v-if='!msg.link' style='pointer-events: none')
						div.msg-image-wrapper(v-if='msg.aq_imagem_principal')
								img(:src='msg.aq_imagem_principal')
						div.info
							p.title {{ msg.nm_titulo }}
							template
								div.conteudo.nm_texto(v-if="msg.aq_imagem_principal && msg.nm_texto?.length > 150" :style="{ color: 'black' }")
									span(v-html="textoFormatado(msg)")
								div(v-else-if="!msg.aq_imagem_principal && msg.nm_texto?.length > 150" :style="{ color: 'black' }")
									span(v-html="textoFormatado(msg)")
								div.conteudo.nm_texto(v-else :style="{ color: 'black', padding: '15px' }")
									span(v-html="textoFormatado(msg)")

			Button.p-button-raised.p-button-rounded.p-button-success.mr-1.p-col-2(v-if="listaGrupos.includes(1)" label="Adicionar Mensagem" @click="dialogCadastroMensagem = true; getGrupos({'clean': 'true'}); limpar_model()")

		div.p-grid.p-align-top.p-col-12.ta-left
			Panel.datatable.p-col-12(header='Mensagens Alerta' v-if="msgs_alerta_todos.length > 0")
				Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
					.p-grid.p-fluid.p-align-end
						.p-lg-4.p-md-4.p-sm-12
							label.form-label Procurar palavra:
							.p-inputgroup
								InputText(
									placeholder='Digite uma palavra'
									@keyup.enter.native='applyFilters(0)'
									@input='(value) => { if (!value) applyFilters(0) }'
									v-model='filters.nm_titulo_texto'
								)
								Button(icon='jam jam-search' @click='applyFilters(0)')

						.p-lg-4.p-md-4.p-sm-12
							label.form-label Vigência:
							.p-inputgroup
								Calendar(
									v-model='filters.dt_vigente_al'
									selectionMode="range"
									dateFormat="dd/mm/yy"
									:locale="ptbr"
									:manualInput='true'
									:touchUI='windowInnerWidth < 576'
									@keyup.enter.native='applyFilters(0)'
									v-tooltip.top="'Este filtro leva em consideração horas, minutos e segundos. Você pode ajustá-los manualmente conforme necessário.'"
								)
								Button(icon='jam jam-search' v-tooltip.top="'Pesquisar'" @click='applyFilters(0)')
								Button.p-button-danger(
									icon='jam jam-rubber' type='button'
									v-tooltip.top="'Limpar'"
									@click='filters.dt_vigente_al=null')

						.p-lg-2.p-md-2.p-sm-12
							.p-inputgroup
								Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters(0)')
				ProgressBar(v-if="waitingTable_al" mode="indeterminate")
				div(v-else)
					Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
					DataTable.table(:value="msgs_alerta_todos_aux")
						Column( field='nm_titulo' header='Título' sortable)

						Column( header='Texto' sortable)
							template(#body='props')
								span(v-if='props.data.nm_texto?.length < 150' style=' overflow: hidden; text-overflow: ellipsis;' v-html="props.data.nm_texto?.slice(0, 150)")
								span(v-else-if='props.data.nm_texto' v-html="props.data.nm_texto?.slice(0, 150) + '...'")
								i(v-else) Sem texto.
						
						Column(field='dt_criado' header='Data de Criação' sortable)

						Column(header='Vigência')
							template(#body='props')
								p(v-if="props.data.dt_final") De {{ props.data.dt_inicial }} a {{ props.data.dt_final }}
								p(v-else) De {{ props.data.dt_inicial }}, sem data final definida.
								div(v-if="!checkVigencia(props.data)")
									Button.p-button-rounded.p-button-warning(icon="pi pi-calendar" v-tooltip.top="'Não vigente.'")

						Column(v-if="listaGrupos.includes(1)" header='Estabelecimentos')
							template(#body='props')
								div(v-if="props.data.nomes_estabs?.length > 0")
									div(v-for="nome_estab in props.data.nomes_estabs")
										p {{ nome_estab }}
								p(v-else) Não especificado.

						Column(v-if="listaGrupos.includes(1)" header='Grupos')
							template(#body='props')
								div(v-if="props.data.nomes_grupos?.length > 0")
									div(v-for="nome_grupo in props.data.nomes_grupos")
										p {{ nome_grupo }}
								p(v-else) Não especificado.

						Column(v-if="listaGrupos.includes(1)" header='Usuários')
							template(#body='props')
								div(v-if="props.data.nomes_usuarios?.length > 0")
									div(v-for="nome_usuario in props.data.nomes_usuarios")
										p {{ nome_usuario }}
								p(v-else) Não especificado.

						Column(headerStyle='text-align: center')
							template(#header) Ações
							template(#body='props').p-grid.p-fluid.p-align-top
								.ta-right.p-buttonset.mt-3
									Button.p-button-raised.p-button-rounded.mr-1(
										v-tooltip.top="'Detalhes'"
										icon="jam jam-search"
										@click="dialogMensagem = true; dialogMensagem_data = props.data; getAnexos(params={'cd_mensagem': props.data.id})"
									)
									Button.p-button-raised.p-button-rounded.p-button-warning.mr-1(
										v-if="listaGrupos.includes(1)"
										v-tooltip.top="'Editar'"
										icon="jam jam-rubber"
										@click="open_cadastro_msg(props.data, msgs_alerta_todos)"
									)
									Button.p-button-raised.p-button-rounded.p-button-danger(
										v-if="listaGrupos.includes(1)"
										icon="jam jam-minus-circle"
										@click="dialogApagar = true; dialogApagar_data = props.data"
									)
					Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")


			Panel.datatable.p-col-12(v-if="listaGrupos.includes(1)" header='Mensagens Fixas (Administração)' style="margin-top: 20px; border: 1px solid green; border-radius: 3px;")

				Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
					.p-grid.p-fluid.p-align-end
						.p-lg-4.p-md-4.p-sm-12
							label.form-label Procurar palavra:
							.p-inputgroup
								InputText(
									placeholder='Digite uma palavra'
									@keyup.enter.native='applyFilters(1)'
									@input='(value) => { if (!value) applyFilters(1) }'
									v-model='filters.nm_titulo_texto'
								)
								Button(icon='jam jam-search' @click='applyFilters(1)')

						.p-lg-4.p-md-4.p-sm-12
							label.form-label Vigência:
							.p-inputgroup
								Calendar(
									v-model='filters.dt_vigente_fi'
									selectionMode="range"
									dateFormat="dd/mm/yy"
									:locale="ptbr"
									:manualInput='false'
									:touchUI='windowInnerWidth < 576'
									@keyup.enter.native='applyFilters(1)'
									v-tooltip.top="'Este filtro leva em consideração horas, minutos e segundos. Você pode ajustá-los manualmente conforme necessário.'"
								)
								Button(icon='jam jam-search' v-tooltip.top="'Pesquisar'" @click='applyFilters(1)')
								Button.p-button-danger(
									icon='jam jam-rubber' type='button'
									v-tooltip.top="'Limpar'"
									@click='filters.dt_vigente_fi=null')

						.p-lg-2.p-md-2.p-sm-12
							.p-inputgroup
								Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters(1)')

				ProgressBar(v-if="waitingTable_fi" mode="indeterminate")
				div(v-else)
					Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
					DataTable.table(:value="msgs_fixas_aux")
						Column(field='nm_titulo' header='Título' sortable)

						Column(header='Texto' sortable)
							template(#body='props')
									span(v-if='props.data.nm_texto?.length < 150' style=' overflow: hidden; text-overflow: ellipsis;' v-html="props.data.nm_texto?.slice(0, 150)")
									span(v-else-if='props.data.nm_texto' v-html="props.data.nm_texto?.slice(0, 150) + '...'")
									i(v-else) Sem texto.
						
						Column(field='dt_criado' header='Data de Criação' sortable)

						Column(header='Vigência')
							template(#body='props')
								p(v-if="props.data.dt_final") De {{ props.data.dt_inicial }} a {{ props.data.dt_final }}
								p(v-else) De {{ props.data.dt_inicial }}, sem data final definida.
								div(v-if="!checkVigencia(props.data)")
									Button.p-button-rounded.p-button-warning(icon="pi pi-calendar" v-tooltip.top="'Não vigente.'")

						Column(v-if="listaGrupos.includes(1)" header='Estabelecimentos')
							template(#body='props')
								div(v-if="props.data.nomes_estabs?.length > 0")
									div(v-for="nome_estab in props.data.nomes_estabs")
										p {{ nome_estab }}
								p(v-else) Não especificado.

						Column(v-if="listaGrupos.includes(1)" header='Grupos')
							template(#body='props')
								div(v-if="props.data.nomes_grupos?.length > 0")
									div(v-for="nome_grupo in props.data.nomes_grupos")
										p {{ nome_grupo }}
								p(v-else) Não especificado.

						Column(v-if="listaGrupos.includes(1)" header='Usuários')
							template(#body='props')
								div(v-if="props.data.nomes_usuarios?.length > 0")
									div(v-for="nome_usuario in props.data.nomes_usuarios")
										p {{ nome_usuario }}
								p(v-else) Não especificado.

						Column(headerStyle='text-align: center')
							template(#header) Ações
							template(#body='props').p-grid.p-fluid.p-align-top
								.ta-right.p-buttonset.mt-3
									Button.p-button-raised.p-button-rounded.mr-1(
										v-tooltip.top="'Detalhes'"
										icon="jam jam-search"
										@click="dialogMensagem = true; dialogMensagem_data = props.data; getAnexos(params={'cd_mensagem': props.data.id})"
									)
									Button.p-button-raised.p-button-rounded.p-button-warning.mr-1(
										v-if="listaGrupos.includes(1)"
										v-tooltip.top="'Editar'"
										icon="jam jam-rubber"
										@click="open_cadastro_msg(props.data, msgs_fixas)"
									)
									Button.p-button-raised.p-button-rounded.p-button-danger(
										v-if="listaGrupos.includes(1)"
										icon="jam jam-minus-circle"
										@click="dialogApagar = true; dialogApagar_data = props.data"
									)
					Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style scoped lang="scss">
		
		.alerta-vigencia {
			cursor: default;
			display: block;
			float: right;  
			z-index: 0;
			position: absolute;
			right: 5px;
			top: 5px;
		}

		.historico-wrapper {
			border: 1px solid #8888;
			border-radius: 5px;
			max-height: 20vh;
			overflow-y: scroll;
			overflow-x: visible;
		}

		.anexo-importantes {
			border: 1px solid #6666;
		}

		.anexo-importantes:hover {
			box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
			cursor: pointer;
			background-color: #f9fcff;
		}

		.file-input-container {
			width: 100%;
			display: flex;
			align-items: center;
			text-align: center;
		}

		.file-input-label {
			border: 1px solid red;
			padding: 15px;
			border: 2px dashed #8888;
			border-radius: 8px;
			height: fit-content;
			align-items: center;
			width: 100%;
			p {
				text-align: center;
			}
			i {
				font-size: 4rem;
				display: block;
				color: #575757;
			}
		}

		.file-input-label:hover {
			cursor: pointer;
			background-color: #bebebe66;
		}

		.file-input {
			display: none;
		}

		.anexos-wrapper {
			border: 1px solid #6666;
			border-radius: 5px;

			a {
				margin-top: 5px;
			}

			.icon {
				font-size: 2rem;
				color: rgb(87, 149, 219);
			}

			.anexo {
				padding: 5px;
				display: flex;
				justify-content:space-around;
				border-radius: 7.5px;
				box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
			}

			.anexo:hover {
				cursor: pointer;
				background-color: #a5a5a566;
			}
		}

		.image-wrapper-form {
			font-size: 0;
			text-align: center;
			margin-top: 10px;
			overflow: hidden;
			min-height: 10vh;
			border: 1px solid #6666;
			border-radius: 5px;

			img {
				width: 100%;
			}
		}

		.modal-title {
			white-space: pre-wrap;
			padding-left: 15px;

		}

		.modal-text {
			white-space: pre-wrap;
			max-height: 50vh;
			overflow: -moz-scrollbars-vertical;
			overflow-y: scroll;
			padding-left: 10px;
			padding-right: 5px;
			box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
			border-radius: 10px;
		}

		.dialogMensagem {
			width: 80vw;
		}

		.dialogCadastroMensagem {
			.p-dialog-content {
				overflow: visible !important;
			}
			.p-multiselect-panel {
				max-width: min-content;
			}
		}

		.card {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			border-radius: 0.75rem;
			background-color: rgb(255, 255, 255);
			width: 100%;
			height: fit-content;
			max-height: 566px;
			margin: 0.5rem;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
			vertical-align: middle;
		}

		.card-fixos {
			border-radius: 0.75rem;
			background-color: rgb(255, 255, 255);
			width: 300px;
			max-height: 315px; /* Alteração aqui */
			min-height: 315px;
			margin: 0.5rem;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
			vertical-align: middle;
			transition: max-height ease-in-out 0.3s; /* Alteração aqui */
			color: black;
			position: relative;
		}

		.conteudo {
			opacity: 0;
			display: none;
			transition: opacity 0.01s ease-in;
			transition: display 0.01s ease-in;
			pointer-events: none;
		}

		.info-link {
			pointer-events: none;
			z-index: 1000;
			font-size: 1.5rem;
			margin-left: 0.5rem;
			color: blue;
		}

		.card-fixos:hover {
			border: 2px solid #6666;
			box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
			cursor: pointer;
			transform: scale(1.015);
		}

		.header {
			position: relative;
			background-clip: border-box;
			margin-top: 1rem;
			margin-left: 1rem;
			margin-right: 1rem;
			border-radius: 0.75rem;
			height: 150px;
		}

		.header-alertas {
			position: relative;
			background-clip: border-box;
			margin-top: 1rem;
			margin-left: 1rem;
			margin-right: 1rem;
			border-radius: 0.75rem;
			background-color: rgb(95, 173, 236);
			box-shadow: 0 10px 15px -3px rgba(25, 25, 26, 0.4),0 4px 6px -4px rgba(33,150,243,.4);
			height: 75px;
		}

		.header-alertas p {
			color: white;
		}

		.header-alertas:hover {
			background-color: rgb(75, 139, 192);
			cursor: pointer;
		}

		.info {
			pointer-events: None;
			text-align: left;
			pointer-events: none;
		}

		.info p {
			text-align: center;
			max-width: 100%;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			color: black;
		}

		p.nm_texto {
			max-width: 100%;
			display: -webkit-box;
			-webkit-line-clamp: 8;
			-webkit-box-orient: vertical;
			color: black;
		}

		.card-fixos:hover .info p {
			overflow: hidden;
			display: block;
		}

		.info-alertas {
			border: none;
			padding: 0.75rem;
			text-align: center;
			overflow: scroll;
		}

		.title {
			color: rgb(38, 50, 56);
			letter-spacing: 0;
			line-height: 1.375;
			font-weight: 600;
			font-size: 1.25rem;
			margin: 0.5rem;
		}

		.card-alerta {
			width: 100%;
			border-radius: 5px;
			background: #f5f5f5;
			position: relative;
			padding: 0.5rem;
			border: 2px solid #c3c6ce;
			transition: 0.2s ease-out;
			overflow: visible;
		}

		.card-alerta:hover {
			border-color: #008bf8;
			box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
			cursor: pointer;
		}

		.popup-image-wrapper {
			display: inline-block;
			justify-content: center;
			width: 100%;
			margin-bottom: 2rem;
		}

		.popup-image-wrapper img {
			transform: scale(1);
			position: relative;
			box-sizing: border-box;
			width: 100%;
			max-height: 50%;
			vertical-align: middle;
			border-radius: 0.75rem;
		}

		.msg-image-wrapper {
			height: 200px;
		}

		.msg-image-wrapper img {
			transform: scale(1);
			position: relative;
			box-sizing: border-box;
			width: 100%;
			max-height: 200px;
			vertical-align: middle;
			border-radius: 0.75rem;
		}

	</style>

	<script>
		import ProgressBar from 'primevue/progressbar'
		import DataView from 'primevue/dataview'
		import Panel from 'primevue/panel'
		import Paginator from 'primevue/paginator'
		import DataTable from 'primevue/datatable'
		import Column from 'primevue/column'
		import Button from 'primevue/button'
		import Tooltip from 'primevue/tooltip'
		import Dialog from 'primevue/dialog'
		import InputText from 'primevue/inputtext'
		import Dropdown from 'primevue/dropdown'
		import ProgressSpinner from 'primevue/progressspinner'
		import wsConfigs from './../../middleware/configs'
		import Mensagem from '../../middleware/controllers/Mensagem'
		import { required, minLength } from 'vuelidate/lib/validators'
		import { maxLength } from 'vuelidate/lib/validators'
		import FileUpload from 'primevue/fileupload'
		import Editor from "primevue/editor";
		import Calendar from 'primevue/calendar'
		import { pCalendarLocale_ptbr } from './../../utils'
		import SelectButton from 'primevue/selectbutton'
		import moment from 'moment'
		import { Estabelecimento, Funcionario, } from './../../middleware'
		import MultiSelect from 'primevue/multiselect'
		import Accordion from 'primevue/accordion';
		import AccordionTab from 'primevue/accordiontab';
		import Message from 'primevue/message';

		export default {
			created () {
				this.getMensagens('', true),
				this.refreshUser(),
				this.getEstabelecimentos()
			},
			components: { ProgressBar, DataView, Panel, Paginator, DataTable,
				Column, Button, Tooltip, Dialog, ProgressSpinner, InputText,
				Dropdown, FileUpload, Editor, Calendar, SelectButton, MultiSelect,
				Accordion, AccordionTab, Message },
			directives: { tooltip: Tooltip },
			data () {
				return {
					waitingTable: false,
					waitingTable_fi: false,
					waitingTable_al: false,
					waitingForm: false,
					waiting_grupos: false,
					waiting_estabelecimento:false,
					ptbr: pCalendarLocale_ptbr,
					msgs_fixas: [],
					msgs_alerta: [],
					msgs_fixas_aux: [],
					msgs_alerta_importante: [],
					msgs_alerta_todos: [],
					msgs_alerta_todos_aux: [],
					anexos: [],
					anexos_add: [],
					anexo: {},
					usuarioInfo: null,
					listaGrupos: [],
					windowInnerWidth: window.innerWidth,
					waitingMensagens: false,
					waitingAnexos: false,
					waitingSalvarAnexos: false,
					dialogMensagem: false,
					dialogMensagem_data: {},
					dialogCadastroMensagem: false,
					dialogCadastroMensagem_data: {},
					dialogAnexos: false,
					dialogApagar: false,
					dialogApagar_data: {},
					dialogApagarAnexo: false,
					dialogApagarAnexo_data: {},
					dialogAlertasImportantes: false,
					filters: {
						nm_titulo_texto: '',
						dt_vigente_al: null, // para a tabela de alertas
						dt_vigente_fi: null // para a tabela de fixos
					},
					options:{
						tipo_msg: [{label: 'Fixa', value: 'FI'}, {label: 'Alerta', value: 'AL'}],
						tipo_popup: [{label: 'Sim', value: true}, {label: 'Não', value: false}],
						status_msg: [{label: 'Ativa', value: true}, {label: 'Inativa', value: false}],
						estabelecimentos_multi:[],
						grupos_multi: [],
					},
					paginator: {
						page: this.$route.query.pg ? this.$route.query.pg : 1,
						per_page: wsConfigs.paginator_perPage,
						count: 0
					},
					model_msg: {
						id: null,
						nm_usuario_cri: null,
						nm_usuario_edi: null,
						nm_titulo: null,
						nm_texto: null,
						link: null,
						aq_imagem_principal: null,
						lista_estabs: [],
						lista_grupos: [],
						lista_usuarios: [],
						dt_inicial: moment()._d,
						dt_final: null,
						ie_popup: false,
						ie_importante: false,
						ie_status: true,
						ie_tipo: 'FI',
						historico_edicoes: ''
					},
					submitted: false,
				}
			},
			validations () {
				let v = {
					model_msg: {
						nm_titulo: { required, minLength: minLength(2), maxLength: maxLength(150) },
						nm_texto: { required, minLength: minLength(2) },
					}
				}

				return v
			},
			methods: {
				checkVigencia(msg) {
					const now = new Date();
					let dtInicial = null;
					let dtFinal = null;

					if (msg.dt_inicial) {
						const dtInicialParts = msg.dt_inicial.split(/[ /:]/).map(part => parseInt(part, 10));
						dtInicial = new Date(dtInicialParts[2], dtInicialParts[1] - 1, dtInicialParts[0], dtInicialParts[3], dtInicialParts[4], dtInicialParts[5]);
					}
				
					if (msg.dt_final) {
						const dtFinalParts = msg.dt_final.split(/[ /:]/).map(part => parseInt(part, 10));
						dtFinal = new Date(dtFinalParts[2], dtFinalParts[1] - 1, dtFinalParts[0], dtFinalParts[3], dtFinalParts[4], dtFinalParts[5]);
					}
					return (now >= dtInicial && now <= dtFinal) || (!dtFinal && now >= dtInicial);
				},
				visualizarImportantes (){
					let mensagens_list = []
					for(let index in this.msgs_alerta_importante){
						mensagens_list.push(this.msgs_alerta_importante[index].id)
					}
					Mensagem.visualizar({"mensagem":mensagens_list})
						.then(response => {
							if (([400]).includes(response.status)) {
								console.log(response.data);
							}
						})
				},
				OpenAlertasImportantes () {
					this.msgs_alerta_importante.sort((a, b) => {
						if (a.visualizado && !b.visualizado) {
							return 1; // 'a' vem depois de 'b' se 'a' estiver visualizado e 'b' não estiver
						} else if (!a.visualizado && b.visualizado) {
							return -1; // 'a' vem antes de 'b' se 'a' não estiver visualizado e 'b' estiver
						}
						return 0; // mantém a ordem atual se ambos estiverem visualizados ou não visualizados
					});

					const nemTodasVisualizadas = !(this.msgs_alerta_importante.every(msg => msg.visualizado));

					this.dialogAlertasImportantes = nemTodasVisualizadas;
					if (nemTodasVisualizadas) { this.getAnexos(); }
				},
				remove_anexo(anexo_obj){
					this.waitingAnexos = true
					Mensagem.deleteAnexos(anexo_obj.id)
						.then(async response => {
							if (([200, 201]).includes(response.status)) {
								this.$toast.success('Anexo deletado.', { duration: 3000 });
							} else if (response.status === 400) {
								this.$toast.error(response.data.detail, { duration: 3000 });
								console.log(response.data);
							}
							await this.getAnexos({ 'cd_mensagem': this.model_msg.id });
							this.waitingAnexos = false;
							this.dialogApagarAnexo = false;
						})
						.catch(error => {
							this.$toast.error('Erro ao deletar Anexo.', { duration: 3000 });
							console.error(error);
							this.waitingForm = false;
						});

				},
				remove_msg(msg_obj) {
					this.waitingForm = true;
					this.model_msg = Object.assign({}, msg_obj);
					this.model_msg.ie_status = false;
					this.model_msg.dt_deletado = moment()._d;
					this.model_msg.nm_usuario_del = this.usuarioInfo.username;
					if (this.model_msg.id) {
						if (this.model_msg.dt_inicial) { this.model_msg.dt_inicial = moment(this.model_msg.dt_inicial, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'); }
						if (this.model_msg.dt_final) { this.model_msg.dt_final = moment(this.model_msg.dt_final, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'); }
					}

					let dataSend = Object.assign({}, this.model_msg);

					if (this.isURL(this.model_msg.aq_imagem_principal)) {
						dataSend.aq_imagem_principal = null;
					}

					Mensagem.save(dataSend)
						.then(response => {
							if (([200, 201]).includes(response.status)) {
								this.$toast.success('Mensagem excluída.', { duration: 3000 });
								this.getMensagens();
								this.dialogApagar = false;
							} else if (response.status === 400) {
								this.$toast.error(response.data.detail, { duration: 3000 });
								console.log(response.data);
							}
							this.waitingForm = false;
						})
						.catch(error => {
							this.$toast.error('Erro ao excluir mensagem.', { duration: 3000 });
							console.error(error);
							this.waitingForm = false;
						});
				},
				open_cadastro_msg(data) {
					this.getAnexos({'cd_mensagem': data.id});
					this.model_msg = Object.assign({}, data);
					this.getGrupos({'clean': 'true'});
					this.dialogCadastroMensagem = true;
				},
				textoFormatado(msg) {
					if (msg && msg.nm_texto) {
						if (msg.aq_imagem_principal || msg.nm_texto?.length > 150) {
							return msg.nm_texto.slice(0, 150) + (msg.nm_texto.length > 150 ? '...' : '');
						} else {
							return msg.nm_texto.slice(0, 250) + (msg.nm_texto.length > 250 ? '...' : '');
						}
					}
					return '';
				},
				limpar_model() {
					this.model_msg = {
						id: null,
						nm_usuario_cri: null,
						nm_usuario_edi: null,
						nm_titulo: null,
						nm_texto: null,
						link: null,
						aq_imagem_principal: null,
						lista_estabs: [],
						lista_grupos: [],
						lista_usuarios: [],
						dt_inicial: moment()._d,
						dt_final: null,
						ie_popup: false,
						ie_importante: false,
						ie_status: true,
						ie_tipo: 'FI',
						historico_edicoes: '',
					};
					this.anexos = [];
					this.anexos_add = [];
				},
				getGrupos(params){
					this.waiting_grupos = true;
					this.options.grupos_multi = [];
					Funcionario.findAllGrupos(params).then(response => {
						if (response.status === 200) {
							this.options.grupos_multi.push({ text: 'TODOS', value: null })
							response.data.forEach(e => {
								this.options.grupos_multi.push({ text: e.nm_grupo, value: e.id })
							})
						} else { console.log(response) }
						this.waiting_grupos = false
					})
				},
				getEstabelecimentos(){
					this.waiting_estabelecimento = true;
					this.options.estabelecimentos_multi = [];
					Estabelecimento.findAllClean().then(response => {
						if (response.status === 200) {
							this.options.estabelecimentos_multi.push({ text: 'TODOS', value: null })
							response.data.forEach(e => {
								this.options.estabelecimentos_multi.push({ text: e.nm_fantasia, value: e.id })
							})
						}
						this.waiting_estabelecimento = false
					})
				},
				isURL(str) {
					// Expressão regular para verificar se a string é uma URL
					let urlRegex = /^(?:https?|ftp):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
					return urlRegex.test(str);
				},
				saveAnexos(id_mensagem) {
					this.waitingSalvarAnexos = true;
					let promises = [];

					for (let anexo of this.anexos_add) {
						let formData = new FormData();
						formData.append('arquivo', anexo);
						formData.append('mensagem', id_mensagem);
						let promise = Mensagem.saveAnexos(formData)
							.then(response => {
								if (([200, 201]).includes(response.status)) {
									this.$toast.success('Anexos salvos.', { duration: 3000 });
									this.anexos_add = [];
									this.anexos = []
									this.getAnexos({'cd_mensagem': id_mensagem});
								} else if (response.status === 400) {
									this.$toast.error(response.data.detail, { duration: 3000 });
									console.log(response.data);
								}
							})
							.catch(error => {
								this.$toast.error('Erro ao salvar anexo.', { duration: 3000 });
								console.error(error);
							});

						promises.push(promise);
					}

					Promise.all(promises)
						.then(() => {
							this.waitingSalvarAnexos = false;
						})
						.catch(error => {
							console.error('Erro ao salvar anexos:', error);
							this.waitingSalvarAnexos = false;
						});
				},
				async handleSubmit() {
					this.waitingForm = true;
					let dataSend = Object.assign({}, this.model_msg);
					if (this.model_msg.id) {
						if (dataSend.dt_inicial) { dataSend.dt_inicial = moment(this.model_msg.dt_inicial, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'); }
						if (dataSend.dt_final) { dataSend.dt_final = moment(this.model_msg.dt_final, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'); }
					}
					if (this.isURL(this.model_msg.aq_imagem_principal)) {
						dataSend['path_imagem_principal'] = dataSend.aq_imagem_principal;
						delete dataSend['aq_imagem_principal'];
					}
					await Mensagem.save(dataSend)
						.then(response => {
							if (([200, 201]).includes(response.status)) {
								this.$toast.success('Mensagem salva.', { duration: 8000 });
								if (this.anexos_add && this.anexos_add.length > 0) {
									this.saveAnexos(response.data.id);
								}
								this.getMensagens();
							} else if (response.status === 400) {
								this.$toast.error(response.data.detail, { duration: 3000 });
								console.log(response.data);
							}
							this.dialogCadastroMensagem = false;
							this.waitingForm = false;
						})
						.catch(error => {
							this.$toast.error('Erro ao salvar mensagem.', { duration: 3000 });
							console.error(error);
							this.waitingForm = false;
						});
				},
				adicionarAnexo(event) {
					const files = event.target.files;
					this.anexos_add = []
					if (files) {
						for (let i = 0; i < files.length; i++) {
							this.anexos_add.push(files[i]);
						}
					}
				},
				convertDate(date){
					return moment(date).format('DD/MM/YYYY hh:mm:ss')
				},
				getAnexos(params) {
					this.waitingAnexos = true
					Mensagem.findAnexos(params).then(response => {
						this.waitingAnexos = false
						this.anexos = []
						if (response.status === 200) {
							for (let anexo in response.data) {
								this.anexos.push({
									'msg_id': response.data[anexo].mensagem,
									'url':response.data[anexo].arquivo,
									'id':response.data[anexo].id,
									'filename':response.data[anexo].filename
								})
							}
						}
					})
				},
				zerar_mensagens_e_anexos(){
					this.msgs_fixas = []
					this.msgs_alerta = []
					this.msgs_alerta_importante = []
					this.msgs_alerta_todos = []
					this.anexos = []
					this.anexos_add = []
				},
				getMensagens(params, open_alertas) {
					this.waitingMensagens = true;
					this.zerar_mensagens_e_anexos();
					Mensagem.findAll(params).then(response => {
						this.waitingMensagens = false;
						if (response.status === 200) {
							for (let msg in response.data){
								if(response.data[msg].dt_inicial) { response.data[msg].dt_inicial = this.convertDate(response.data[msg].dt_inicial) }
								if(response.data[msg].dt_final) { response.data[msg].dt_final = this.convertDate(response.data[msg].dt_final) }
								if(response.data[msg].dt_criado) { response.data[msg].dt_criado = this.convertDate(response.data[msg].dt_criado) }

								if (response.data[msg].ie_tipo == 'FI'){
									this.msgs_fixas.push(response.data[msg]);
								} else if (response.data[msg].ie_tipo == 'AL') {
									this.msgs_alerta_todos.push(response.data[msg]);
									if (response.data[msg].ie_importante) {
										this.msgs_alerta_importante.push(response.data[msg]);
									} else {
										this.msgs_alerta.push(response.data[msg]);
									}
								}
							}
							// para usar nas tables
							this.msgs_fixas_aux = this.msgs_fixas
							this.msgs_alerta_todos_aux = this.msgs_alerta_todos
						}
						if (open_alertas && this.msgs_alerta_todos.length > 0 && !this.listaGrupos.includes(1)) { this.OpenAlertasImportantes() }
					})
				},
				refreshUser() {
					let usuario = JSON.parse(localStorage.getItem("usuario"));
					if (usuario) {
						let usuarioInfo = {
							username: usuario.username,
							firstName: usuario.firstName,
							lastName: usuario.lastName,
							estabelecimentoName:
								usuario.estabelecimentos[
									usuario.idxCurrentEstabelecimento
								].nm_fantasia,
							grupoTitle: ""
						};
						this.usuarioInfo = usuarioInfo;
						for (let grupo in usuario.grupos) {
							this.listaGrupos.push(usuario.grupos[grupo].id)
						}
					} else {
						this.menuItems = [];
						this.usuarioInfo = null;
					}
				},
				async getList(params, table) {
					if (table == 0) {
						this.waitingTable_al = true
						params['dt_vigente'] = params['dt_vigente_al']
						delete params['dt_vigente_al']
					} else if (table == 1) {
						this.waitingTable_fi = true
						params['dt_vigente'] = params['dt_vigente_fi']
						delete params['dt_vigente_fi']
					}
					return Mensagem.findAll(params).then(response => {
						if (response.status == 200) {
							if (table == 0) {
								this.msgs_alerta_todos_aux = []
							} else if (table == 1) {
								this.msgs_fixas_aux = []
							}
							for (let msg in response.data.results) {

								if(response.data.results[msg].dt_inicial) {
									response.data.results[msg].dt_inicial = this.convertDate(response.data.results[msg].dt_inicial)
								}
								if(response.data.results[msg].dt_final) {
									response.data.results[msg].dt_final = this.convertDate(response.data.results[msg].dt_final)
								}
								if(response.data.results[msg].dt_criado) {
									response.data.results[msg].dt_criado = this.convertDate(response.data.results[msg].dt_criado)
								}

								if (response.data.results[msg]) {
									if (response.data.results[msg].ie_tipo == 'FI' && table == 1) {
										this.msgs_fixas_aux.push(response.data.results[msg]);
									} else if (response.data.results[msg].ie_tipo == 'AL' && table == 0) {
										this.msgs_alerta_todos_aux.push(response.data.results[msg]);
									}
								}
							}
							this.paginator.count = response.data.count
							this.list = response.data.results
						}
						if (table == 0) {
							this.waitingTable_al = false
						} else if (table == 1) {
							this.waitingTable_fi = false
						}
						return true
					})
				},
				applyFilters (table) {
					let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
					if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
					Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
					this.getList(params, table)
				},
				clearFilters (table) {
					this.paginator.page = 1
					Object.keys(this.filters).forEach(k => { if (k !== 'cd_guia_tipo') this.filters[k] = null })
					this.applyFilters(table)
				},
				onPage (ev) {
					this.paginator.page = ev.page + 1
					this.applyFilters()
				},
				toBase64 (file) {
					return new Promise((resolve, reject) => {
						let reader = new FileReader();
						reader.readAsDataURL(file);
						reader.onload = () => resolve(reader.result);
						reader.onerror = error => reject(error);
					})
				},
				async beforeSendImage(ev) {
					ev.xhr.open('GET', '/')

					if (! ev.formData.get('aq_foto').type.includes("image/")) {
						this.$toast.error("Tipo do arquivo é inválido! Precisa ser imagem.")
						return
					}
					// Max size 2mb
					if (! ev.formData.get('aq_foto').size > 2097152) {
						this.$toast.error("Tamanho do arquivo é inválido! Tamanho máximo: 2mb.")
						return
					}

					this.model_msg.aq_imagem_principal = await this.toBase64(ev.formData.get('aq_foto')).then( response => response)
					this.model_msg.path_foto = URL.createObjectURL(ev.formData.get('aq_foto'))

				},
			}
		}
	</script>

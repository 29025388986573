import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find(`/medclub/comunicados/`, params)
const findAnexos = (params) => GenericDAO.find(`/medclub/comunicados/anexos/`, params)
const saveAnexos = (params) => GenericDAO.save(`/medclub/comunicados/anexos/`, params)
const deleteAnexos = (id) => GenericDAO.remove(`/medclub/comunicados/anexos/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/comunicados/`, dataSend)
const visualizar = (dataSend) => GenericDAO.save(`/medclub/comunicados/visualizacoes/`, dataSend)

export default {
    findAll,
    findAnexos,
    saveAnexos,
    deleteAnexos,
    save,
    visualizar
}
